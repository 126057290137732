<template>
  <!-- Modal -->
  <div class="modal fade" data-backdrop="static" id="NewAccidentIncident"  role="dialog"
       aria-labelledby="NewAccidentIncidentTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <form class="modal-content" @submit.prevent="addAccidentIncident">
        <div class="modal-header">
          <h5 class="modal-title" id="NewAccidentIncidentTitle">ADD ACCIDENT INCIDENT REPORT</h5>
<!--          <button type="button" class="close"  @click.prevent="cancelAdd">-->
<!--            <span aria-hidden="true">&times;</span>-->
<!--          </button>-->
        </div>
        <div class="modal-body">
            <div class="form-group row no-gutters mb-0">
              <label for="VesselList" class="font-weight-bold col-sm-2 col-form-label text-right pr-2">Vessel</label>
              <div class="col-sm-10">
                <select class="form-control form-control-sm text-uppercase" id="VesselList" v-model="newReport.vessel_id" required>
                  <option :value="null">—— Select Vessel ——</option>
                  <option v-for="vessel in vesselKeys"
                          :key="vessel.id"
                          :value="vessel.id">
                    {{ vessel.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row no-gutters mb-1">
              <label for="ReportTitle" class="font-weight-bold col-sm-2 col-form-label text-right pr-2">Report Title</label>
              <div class="col-sm-10">
                <textarea class="form-control form-control-sm" id="ReportTitle" rows="3" v-model="newReport.title" required></textarea>
              </div>
            </div>
            <div class="form-group row no-gutters mb-0">
              <label for="EventType" class="font-weight-bold col-sm-2 col-form-label text-right pr-2">Type of Event</label>
              <div class="col-sm-10">
                <select class="form-control form-control-sm text-uppercase" id="EventType" v-model="newReport.eventClass">
                  <option :value="null">—— Select Type of Event ——</option>
                  <option class="text-uppercase" v-for="event in eventOneKeys" :key="event.id" :value="event.id">
                    {{ event.code }}) {{ event.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row no-gutters mb-0">
              <label for="EventDate" class="font-weight-bold col-sm-2 col-form-label text-right pr-2">Event Date</label>
              <div class="col-sm-10">
                <input type="date" :min="minimumDate"  :max="currentDate" class="form-control form-control-sm" id="EventDate" v-model="newReport.eventDate" required>
              </div>
            </div>
            <div class="form-group row no-gutters mb-0">
              <label for="LocalTime" class="font-weight-bold col-sm-2 col-form-label text-right pr-2">Local Time</label>
              <div class="col-sm-10">
                <input type="time" class="form-control form-control-sm" id="LocalTime" v-model="newReport.localTime">
              </div>
            </div>
          <div class="form-group row no-gutters mb-0">
            <label for="EventClass" class="font-weight-bold col-sm-2 col-form-label text-right pr-2">
              Event Class
              <font-awesome-icon
                icon="question-circle"
                data-toggle="modal"
                style="cursor:pointer"
                data-target="#eventClassDescription"
              />
            </label>
            <div class="col-sm-10">
              <select class="form-control form-control-sm text-uppercase" id="EventClass" v-model="newReport.eventClassImpact">
                <option :value="null">—— Select Event Class ——</option>
                <option class="text-uppercase" v-for="event in eventImpactKeys" :key="event.id" :value="event.id">
                  {{ event.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row no-gutters mt-1">
            <label for="isConfidential" class="font-weight-bold col-sm-2 col-form-label text-right pr-2">Confidential Report ?</label>
            <div class="col-sm-10">
              <select class="form-control form-control-sm text-uppercase" id="isConfidential" v-model="newReport.is_confidential_report">
                <option :value="true">YES</option>
                <option :value="false">NO</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-secondary" @click.prevent="cancelAdd">Close</button>
          <button type="submit" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" class="btn btn-sm btn-primary" :disabled="hasChanges === false">Add Report</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import jQuery from 'jquery'
import DateMixin from "@/mixins/DateMixin"
import { ReportService } from '../../services/ReportService'
import { AlertService } from '../../services/AlertService'
import {DataService} from "@/services/DataService";
// eslint-disable-next-line no-undef
let $ = jQuery
export default {
  name: 'NewAccidentIncidentReport',
  mixins:[DateMixin],
  data () {
    return {
      newReport: {
        vessel_id: null,
        report_type_id: 1, // 1 since this is accident incident report
        title: '',
        // todo form break
        eventClass: null,
        eventClassImpact: null,
        eventDate: '',
        localTime: '',
        timezone: null,
        is_confidential_report:false
      },
      closeButtonClick : false
    }
  },
  methods: {
    ...mapActions(['getVesselKeys', 'getTimezoneKeys', 'getEventOneKeys','getEventImpactKeys']),
    async addAccidentIncident () {
      if (await AlertService.confirmCreateAlert()) {
        const report = await ReportService.addReport(this.newReport)
        if(report){
          $('body').removeClass('modal-open')
          $('.modal-backdrop').remove()
          $('.fade').remove()
          $('.show').remove()
          // AlertService.successAlert('Adding Accident Incident', 'ADD')

          successAlert.fire({
            title: `NEW ACCIDENT/INCIDENT REPORT ADDED SUCCESSFULLY`,
            showConfirmButton: true,
            allowOutsideClick:false,
            allowEnterKey:false,
            allowEscapeKey:false,
            timer: 1500,
            scrollbarPadding: false
          }).then(async (result)=>{
            if(result.isConfirmed){
              await this.$router.push({ name: 'AccidentIncidentReport', params: { id: report.id } }).catch(() => {
              })
            }
          })

          setTimeout(() => this.$router.push({name: 'AccidentIncidentReport', params: {id: report.id}}), 3000);

        }
      }
    },
   async cancelAdd() {
      const response=await AlertService.cancelAlert();
      if(response){
        this.closeButtonClick = true;
        $('#NewAccidentIncident').modal('hide');
        this.newReport={
            vessel_id: null,
            report_type_id: 1,
            title: '',
            eventClass: null,
            eventClassImpact: null,
            eventDate: '',
            localTime: '',
            timezone: null,
        }
        this.closeButtonClick = false;
      }
   },
    preventModalToCloseInEscKey() {
      $('#NewAccidentIncident').on('hide.bs.modal', (event) => {
        if (this.closeButtonClick === false || this.closeButtonClick === undefined) {
          event.preventDefault();
        }
      });
    },
  },
  async created () {
    await this.getVesselKeys()
    await this.getTimezoneKeys()
    await this.getEventOneKeys()
    await this.getEventImpactKeys()
  },
  mounted() {
    this.preventModalToCloseInEscKey();
  },
  computed: {
    ...mapGetters(['vesselKeys', 'timezoneKeys', 'eventOneKeys','eventImpactKeys']),
    hasChanges() {
      const defaultData = {
        vessel_id              : null,
        report_type_id         : 1,
        title                  : '',
        eventClass             : null,
        eventClassImpact       : null,
        eventDate              : '',
        localTime              : '',
        timezone               : null,
        is_confidential_report : false
      };

      let editedData = {
        vessel_id              : this.newReport.vessel_id,
        report_type_id         : this.newReport.report_type_id,
        title                  : this.newReport.title,
        eventClass             : this.newReport.eventClass,
        eventClassImpact       : this.newReport.eventClassImpact,
        eventDate              : this.newReport.eventDate,
        localTime              : this.newReport.localTime,
        timezone               : this.newReport.timezone,
        is_confidential_report : this.newReport.is_confidential_report ?? false,
      };

      return DataService.checkIfChanged(defaultData, editedData);
    }
  }
}
</script>

<style scoped>

</style>
